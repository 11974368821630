/*! Login */

.auth-background { width: 100%; height: 100%; background: url(../img/login-bg.jpg) no-repeat center center; position: absolute; background-size: cover; }


.login-panel { position: absolute; left: 50%; top: 50%; width: 300px; margin: 0; background: #fff; padding: 0 0 20px; border-radius: 5px; box-shadow: 0 0 10px rgba(0,0,0,.5); overflow: hidden; transform: translate(-50%,-50%);
	h1 { padding: 20px; color: #fff; text-transform: uppercase; color: #fff; margin: 0 0 30px; background: #083a48; font-size: 1.6em; }
	.form-stacked { font-size: 1.2em; padding: 0; }
	.form-stacked input { font-size: 125%; padding: 10px 10px; width: 100%; }
	.btn-primary { font-size: 1.2em; }
	.login-actions { padding: 10px 20px; border: none; }
	.show-feedback &,
	&.form-has-error { animation: shake 0.82s linear both;  }
}
.forgot-password { position: absolute; margin: 0; left: 20px; bottom: 0; 
	a { color: #666; text-decoration: none; position: relative;
		&:after { content: ''; position: absolute; left: 50%; right: 50%; bottom: -2px; height: 1px; border-bottom: 1px dotted #666; transition: right .25s ease, left .25s ease; }
		&:hover:after { right: 0; left: 0; }
	}
	
}
.absolute-label { position: relative; padding: 10px 0 0; margin: 0 20px 15px;
 label { position: absolute; left: 11px; top: 11px; background: #fff; padding: 2px 3px; transition: all .25s ease; color: #999; }
 input:valid + label,
 label.full,
 input:focus + label { position: absolute; top: -12px; left: 0; }
 input.has-error { border-color: #900; }
 em.has-error { position: absolute; font-size: 10px; right: 0; bottom: -8px; font-style: normal; background: #fff; color: #900; padding: 2px 6px; }
}

.forgot-password-text { margin: 0 20px 20px; }

.reset-password-explanation { margin: 0 20px 30px; 
    span { display: block; background: #ededed; padding: 5px 10px; border-radius: 3px; }
}


@keyframes shake {
  10%, 90% {
    margin-left: -1px;
  }
  
  20%, 80% {
    margin-left: 2px;
  }

  30%, 50%, 70% {
    margin-left: -4px;
  }

  40%, 60% {
    margin-left: 4px;
  }
}

.login-form input:-webkit-autofill { -webkit-box-shadow: 0 0 0px 1000px white inset; }

.login-form { transition: opacity .25s ease; position: relative; }
.submitted-form-spinner { position: absolute; bottom: 20px; right: 20px; width: 74px; height: 35px; background: $primary-colour; border-radius: 25px; pointer-events: none; opacity: 0; margin-top: 0; }
.valid-form-submission {
    .submitted-form-spinner { animation: startSpinner .75s; animation-fill-mode: forwards;  }
    form { opacity: 0; }

}

@keyframes startSpinner {
    0% { opacity: 0; }
    33.33% { opacity: 1; }
    100% { bottom: 50%; right: 50%; transform: translate(50%,80%); width: 50px; height: 50px; opacity: 1; margin-top: 30px; background: #ededed; }
}

.loader{ height: 50px; width: 50px; padding-top: 5px; text-align: center; display: inline-block; vertical-align: top; 
    svg path,
    svg rect { fill: $primary-colour; }
}