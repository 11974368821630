/*! Feedback */

.feedback-message--blind { position: fixed; top: 25px; left: 50%; text-align: left; z-index: 10000; transition: top .8s ease-in, opacity .5s ease-in; opacity: 1; padding: 15px 30px; width: 600px; border-radius: 4px; margin: 0 0 0 -300px;
	 &.positive {  background: $success; box-shadow: 0 12px 20px -10px rgba(92, 198, 96, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(92, 198, 96, 0.2); }
     &.negative {  background: $negative; box-shadow: 0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2);  }
     &.info { background: $pending; box-shadow: 0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2); z-index: 1001; }
	&.hide-feedback { top: -80px; opacity: 0; }
	&.positive p { color: #fff; margin: 0; height: 50px; line-height: 50px; font-size: 1.4em; }
    &.negative p { color: #fff;  font-size: 1.4em; display: table-row;}
	.close-feedback { position: absolute; right: 0; top: 50%; margin: -25px 0 0; display: block; width: 50px; height: 50px; color: #fff; line-height: 50px; text-align: center; font-size: 1.2em; }
}

.feedback-message { padding: 30px; border-radius: 4px; background: $negative; margin: 0 auto 20px; max-width: $max-panel-width; color: #fff; text-align: center; position: relative; box-shadow: 0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2);
	.close-feedback { position: absolute; right: 0; top: 50%; margin: -25px 0 0; display: block; width: 50px; height: 50px; color: #fff; line-height: 50px; text-align: center; font-size: 1.2em; }
	p:last-of-type { margin: 0; }
}
