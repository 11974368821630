/*Overlays*/

.overlay-donald-russell{ width: 980px; transform: translate(-50%,-50%); padding: 0; position: absolute; background: #fff; left: 50%; top: 50%; box-shadow: 0 0 30px rgba(0,0,0,.4);
                         .close-overlay { display: block; width: 40px; height: 40px; top: 12px; right: 0; z-index: 20; display: flex; align-items: center; justify-content: center; color: $primary-colour; }
}

.overlay-donald-russell--address { width: 500px; }

.overlay-logs{
    width:900px;
}

/*! Address Overlay */

.overlay-address{
    width:500px;
    margin-top: -250px;
    margin-left: -250px;
    .overlay-footer, .overlay-header{
        background: #dcdcdc;
        padding: 20px 20px 5px 20px;
    }
    .overlay-footer{
        padding: 20px 20px 5px 20px;
    }
    .overlay-header{
        padding: 20px;
        margin: 0 0 20px;
    }
}

/*Form/Lister panels */

.in-page-navigation-panel .page-header, .in-page-navigation, .form-panel{
    max-width: 980px;
    width: 980px;
}

.page-header, .lister-panel{
    max-width: 1100px;
    width: 1100px;
}

.list-table th, .gu-mirror th, .list-usage-table th {
    text-transform: capitalize;
}

.tag-filter-dropdowns, .pagination-thumb-view-filter, .selected-tag-filters {
    max-width: 1100px;
}

.form-inline{
    .date-range-filter{
        float: right;
        margin: 0;
        input[type="submit"]{
            margin-left: 15px;
        }
    }
}

.list-table th:empty {
    background: white; border-top-color: #fff;
}

.col-nowrap{
    white-space: nowrap;
}

.title-legend:after {
    background: linear-gradient(to right, #083a48 0%, #083a48 100%);
}

.float-right{
    float: right;
}

.standard-form-input.p-20{
    padding: 20px 20px 5px 20px;
}


.static-page { max-width: 980px; margin: 20px auto 30px;  }
.static-page__content {  margin: 0 auto; }
.static-page__block { border-bottom: 1px solid #ededed; background: #fff; box-shadow: 0 0 5px rgba(0,0,0,.3); margin: 0 0 40px; }
.static-page__block__heading { border-bottom: 1px solid #ededed; padding: 20px; position: relative; background: #f5f5f5; margin: 0;
                               h2 { font-size: 20px; font-weight: 400; margin: 0; color: $primary-colour; }
                               .btn { position: absolute; right: 20px; top: 50%; transform: translateY(-50%); }
}
.static-page-line { display: flex; font-size: 1.3em; padding: 15px 20px; margin: 0; border-bottom: 1px solid #f5f5f5; }
.static-page-label { width: 220px; text-align: right; padding: 0 10px 0 0; color: #666; }
.static-page-answer { flex-grow: 1; text-align: left; }

.col-cust-nav,
.static-page-line--nav-id { background: #e2eef2 !important; }
.col-cust-web,
.static-page-line--web-id { background: #f9f1e5 !important; }

.static-page-sub-heading { position: relative; padding: 20px; border-bottom: 1px solid #ededed; background: #f5f5f5;
                           h3 { font-size: 18px; color: #666; margin: 0;}
                           .btn { position: absolute; right: 20px; top: 50%; transform: translateY(-50%); }
}
.static-page-sub-heading--top-border { border-top: 1px solid #ededed; }

.order-heading { padding: 10px 0 0; margin: 0; }
.order-heading-order-value { display: inline-block; padding: 5px 15px; background: #f5f5f5; font-size: 16px; border-radius: 3px; color: $primary-colour; margin: 0 10px 0 0; }
.order-heading-order-value--price { float: right; }

.lister-email-address-holder { display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 140px; }

.col-date { width: 100px; text-align: center; }
.col-orders { width: 60px; text-align: center; }
.col-postcode,
.col-status { width: 90px; text-align: center; }
.col-customer-id,
.col-id { width: 80px; text-align: center; }
.col-telephone { width: 140px; }
.col-type { width: 120px; }
.col-order-number { width: 100px; text-align: center; }
.col-country { width: 66px; }
.col-cust-web,.col-cust-nav { width: 95px; }
.col-channel { width: 120px; }
.col-price { width: 80px; }
.col-comm-title { width: 300px; }
.col-yes-no { width: 80px; text-align: center;}
.col-frequency { width: 140px; }
//.col-yes-no--yes { background: #E8F5E9; }
.col-yes-no--no { background: #FFEBEE; }

.status { display: inline-block; padding: 5px 10px; border-radius: 3px; }
.status--cancelled { background: #FFEBEE; }

.multiple-addresses-holder { display: flex; flex-wrap: wrap;
                             .static-page__content { width: 50%; }
                             .static-page__content:nth-child(odd) { border-right: 1px solid #ededed; }
                             .static-page-label { width: 100px; }
}

.in-page-orders-stats { display: flex; margin: 0; color: $primary-colour; border-top: 1px solid #ccc; }
.order-stat { border-left: 1px solid #ddd; flex: 1; text-align: center; padding: 20px 0 0; font-size: 13px; background: #f5f5f5; box-shadow: inset 0 2px 1px rgba(0,0,0,.05), 0 3px 2px rgba(0,0,0,.05); position: relative; z-index: 2;
              span { display: block; text-align: center; font-size: 24px; margin: 20px 0 0; padding: 20px; background: #fff; color: $primary-colour;  }
              &:first-child { border: none; }
}

.in-page-navigation-panel { margin: 0 0 30px; }

.filter-panel {
    .radio { padding-top: 5px;
             label { margin-right: 10px; }
    }
}