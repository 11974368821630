/*! Reset Password or Forgotten password Form Rules*/

.char_type_valid{ color: #f96332; }

div.standard-form-input{ margin-top: 15px; }

input#existingpassword { height: 30.33px; }

#rulescontainer li{ margin-right: 10px; margin-bottom: 10px;  }

i.fa.fa-circle.fa-lg{ font-size: 5px !important;}

#reset-password-form ul{ list-style: none; }

#rulescontainer{ display: inline-grid; }

#rulescontainer .password-rules-meta .password-rules-lock-image{
   background-image: url(https://static.adobelogin.com/renga-idprovider/resources/2809bf55396473816a4a9f9d94201182/spectrum/img/sprite.svg); 
}

.password-rules-lock-image {
    background-position: -134px -126px;
    display: inline-block;
    height: 16px;
    margin-right: 12px;
    vertical-align: middle;
    width: 12px;
}

#rulescontainer .password-rules-title { display: inline-block; vertical-align: middle; margin-left: -4px; font-size: 12px; }

#rulescontainer .password-rules-meta { margin-bottom: 16px; }

#rulescontainer{ margin-left: 212px; }

#rulescontainer .password-rules-list{ padding: 0 0 0 0; margin-bottom: 20px; }

#rulescontainer .password-rules-list li i{ margin: 8px 0 0 0; }

#rulescontainer .fa.fa-lg.fa-check{ font-size: 12px; margin-right: 12px;  }

#rulescontainer .fa.fa-lg.fa-exclamation-triangle{  font-size: 12px; margin-right: 12px; }

#rulescontainer #bad-password, 
#rulescontainer .fa.fa-lg.fa-exclamation-triangle, 
#rulescontainer #max, 
#rulescontainer #history{ color: #f53c3c; }

#rulescontainer .fa.fa-lg.fa-circle { margin: 0 16px 0 3px; }

#rulescontainer .none { display: none !important; }

#rulescontainer .block { display: block; }

#rulescontainer .red-border{ border: 1px solid #dd4b39; }

.test-morgan{
    pointer-events: inherit;
    pointer-events: initial;
    pointer-events: unset;  
}

/*Only for forgotten password form */
.login-panel.panel #rulescontainer{ margin-left: 22px; }

#rulescontainer ul.password-rules-list{ list-style: none; }

#lower-upper-case{ display: flex; }

i#lower-upper-case-icon.fa.fa-circle.fa-lg { margin-top: 10px !important; }