/* Variables */

$sm-width: 480px; 
$md-width: 768px; 
$lg-width: 1024px;
$xl-width: 1200px;

$sm-width-under: 479px;
$md-width-under: 767px;
$lg-width-under: 1023px;
$xl-width-under: 1199px;

$xs-max-width: 480px;
$sm-max-width: 768px;
$md-max-width: 1024px;
$lg-max-width: 1366px;
$xl-max-width: 1366px;


$column-padding: 20px;
$column-gutter: 10px;
$column-border: #000;

$link-colour: #083a48;
$link-colour-hover: #000;


$fade-transition: background .25s ease-in-out, color .25s ease-in-out;

$border-radius: 0; 
$border-radius-inner: 0;

$primary-colour: #083a48;
$text-on-primary: #fff;

$secondary-colour: #dc582d;

$banner-sub-colour: #fc0;
$font-stack: 'Open Sans', sans-serif;


$max-panel-width: 800px;

$datepicker-weekday-bg: darken($primary-colour, 7%) !default;
$datepicker-date-bg: $primary-colour !default;
$datepicker-year: rgba(255, 255, 255, .4) !default;
$datepicker-focus: rgba(0,0,0, .05) !default;
$datepicker-selected: $primary-colour !default;
$datepicker-selected-outfocus: desaturate(lighten($primary-colour, 35%), 15%) !default;

$success: #5cb860;
$archived: #996;
$pending: #f90;
$published: #696;
$negative: #da3124;


/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}