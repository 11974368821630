/*! Forms */

$slideWidth: 600px;
$taggingSlideWidth: 1000px;

body.slide-showing { overflow: hidden; }

.slide-holder { position: fixed; right: 0; top: 0; bottom: 0; width: 0; z-index: 10000;
	&.visible { width: $slideWidth; }	
}

.slide-bg { position: fixed; right: 0; top: 0; width: 0; bottom: 0; background: rgba(0,0,0,.4);
    .visible & { width: 100%; }
}
.slide { position: absolute; right: -$slideWidth; top: 0; bottom: 0; background: #fff; transition: right .25s ease; overflow-y: auto; width: $slideWidth; box-shadow: -5px 0 5px rgba(0,0,0,.2);
    .visible & { right: 0 !important; }
    .panel { box-shadow: none; }
    .action-buttons { position: absolute; left: 0; right: 0; bottom: 0; padding: 20px 20px 30px; margin: 0; box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
}

.tagging-slide-holder { 
    &.visible { width: $taggingSlideWidth; }
    .slide { right: -$taggingSlideWidth; width: $taggingSlideWidth; }
    .fixed-centre-content,
    .action-buttons { max-width: none; background: #fff; }
    
}

.close-slide-holder { position: absolute; right: 0; top: 10px; 
    .close-slide { position: static; height: 40px; width: 40px; line-height: 40px; text-align: center; display: block; color: $primary-colour; }
}

.slide-content {position: absolute; left: 0; right: 0; top: 0; bottom: 93px; overflow: auto; padding-bottom: 20px; }