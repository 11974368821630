.filter-panel { background: #f5f5f5; margin: -30px auto 30px;  padding: 12px 0; position: relative; box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);min-height: 56px;
    form { text-align: left; position: relative; width: 980px; margin: 0 auto; }
}

.toggle-list-thumb-view {display: block; list-style: none;padding: 0;height: 40px;border: 1px solid #ededed;border-radius: 25px;overflow: hidden;float: right;
    li { display: block; border-left: 1px solid #ededed; height: 100%; float: left; }
    svg { width: 16px; height: 16px;
        path { fill: #666; }
    }
    li:first-child { border-left: none;
        svg { width: 20px; height: 20px; transform: translateX(3px); }
    }
    a { display: flex; height: 100%; align-items: center; justify-content: center; width: 50px; background: #fff; transition: background .25s ease;
        &:hover { background: #f6f6f6; }
    }
    .on path { fill: $primary-colour; }
}
